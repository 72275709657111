<template>
  <base-section
    id="info-alt"
    space="56"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="5"
        >
          <v-theme-provider :dark="dark">
            <div>
              <base-info-card
                title="Oficina de rectoría e información general"
                pace="6"
              />
              <base-avatar-card
                icon="mdi-cellphone"
                :outlined="false"
                color="transparent"
                horizontal
                space="0"
                title="3175017986"
              />
            </div>
          </v-theme-provider>
          <br>
          <v-theme-provider :dark="dark">
            <div>
              <base-info-card
                title="Coordinación academica"
                pace="6"
              />
              <base-avatar-card
                icon="mdi-cellphone"
                :outlined="false"
                color="transparent"
                horizontal
                space="0"
                title="3175884663"
              />
            </div>
          </v-theme-provider>
          <br>
          <v-theme-provider :dark="dark">
            <div>
              <base-info-card
                title="Coordinación de areas técnicas"
                pace="6"
              />
              <base-avatar-card
                icon="mdi-cellphone"
                :outlined="false"
                color="transparent"
                horizontal
                space="0"
                title="3216653740"
              />
            </div>
          </v-theme-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-theme-provider :dark="dark">
            <div>
              <base-info-card
                title="Rectoria industrial"
                pace="6"
              />
              <base-avatar-card
                icon="mdi-cellphone"
                :outlined="false"
                color="transparent"
                horizontal
                space="0"
                title="3156716758"
              />
            </div>
          </v-theme-provider>
          <br>
          <v-theme-provider :dark="dark">
            <div>
              <base-info-card
                title="Coordinación sedes"
                pace="6"
              />
              <base-avatar-card
                icon="mdi-cellphone"
                :outlined="false"
                color="transparent"
                horizontal
                space="0"
                title="3174043685"
              />
            </div>
          </v-theme-provider>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionKeepInTouch',
    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },
    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Direccion',
          text: 'CRA 30 Callejón Morales Tuluá',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Telefono',
          text: '(+57) 3175017986',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'info@ieindustrialtulua.edu.co',
        },
      ],
    }),
  }
</script>
